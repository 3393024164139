import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isShowElectionData: boolean;
  isShowLimit: boolean;
  isNotFoundElection: boolean;
  isShowLimitAndNotFoundElection:boolean;
  electionData:Array<object>;
  isCircularProgressIndicator: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApiIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiExampleCallId: any;
  ballotpdaCalllId: string = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isShowElectionData: true,
      isShowLimit: false,
      isNotFoundElection: false,
      isShowLimitAndNotFoundElection:false,
      electionData:[],
      isCircularProgressIndicator: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handleResForQelectionStats('message',message)

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.apiExampleCallId === apiRequestCallId) {
        this.showAlert("Respnce JSON", JSON.stringify(responseJson));
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(){
    this.setState({isCircularProgressIndicator:true});
    this.getApi();
  }

  getApi = async() => {
    
    let zipcodeFromLocalStorage=await getStorageData("Zipcode")
    let token=await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ballotpdaCalllId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bellotpdyaElctonDAtaGetEndpoint}?ballotpedia[zip_code]=${zipcodeFromLocalStorage}` 
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 
 
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForQelectionStats = async (from: string, message: Message) => {
    
    if (
      this.ballotpdaCalllId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    
      if(responseJson.data){
        const groupedData = this.groupElectionsByDate(responseJson.data.elections);
        
        this.setState({ electionData: groupedData, isCircularProgressIndicator: false });
      
        setStorageData("groupedData",JSON.stringify(groupedData))

        }else if (responseJson.error) {
          this.setState({isNotFoundElection: true ,isShowLimitAndNotFoundElection:true });

        }
        
      }
    };
    

    groupElectionsByDate(elections:any) {
      const grouped = elections.reduce((acc:any, election:any) => {
          const formattedDate = moment(election.date, "YYYY-MM-DD").format("MMMM Do, YYYY");

          let dateGroup = acc.find((group:any) => group.date === formattedDate);

          if (!dateGroup) {
              dateGroup = { date: formattedDate, elections: [] };
              acc.push(dateGroup);
          }

          dateGroup.elections.push(election);

          return acc;
      }, []);

      return grouped;
  }



  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  handleNavigateOnQuiz() {
    this.props.navigation.navigate("MultipageForms")
  }

  handleBackToHome() {
    this.props.navigation.navigate("LandingPage")
  }

  handleBackArrow() {
    this.props.navigation.navigate("Multipageforms")
  }

  doButtonPressed() {
    const header = {};

    const data = {};

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiExampleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
